import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';

// font awesome
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// library.add(faEye, faEyeSlash);
// "@fortawesome/fontawesome-svg-core": "^1.2.36",
// "@fortawesome/free-solid-svg-icons": "^5.15.4",
// "@fortawesome/vue-fontawesome": "^2.0.2",

// const app = createApp(App);

// // app.component("font-awesome-icon", FontAwesomeIcon);

// app
//   .use(store)
//   .use(router)
//   .mount("#app");

// import { auth,onAuthStateChanged } from "@/services/firebase";

//   import * as Sentry from "@sentry/vue";
//   import { Integrations } from "@sentry/tracing";

//   Sentry.init({
//     Vue,
//     dsn:
//       "https://a4ad2e6b81d84b38a2ef603c47bdb8f8@o413832.ingest.sentry.io/5620510",
//     integrations: [new Integrations.BrowserTracing()],

//     release:
//       process.env.VUE_APP_NAME +
//       "@" +
//       process.env.VUE_APP_VERSION +
//       "@" +
//       process.env.VUE_APP_GIT_VERSION,

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//     logErrors: true
//   });

let app = createApp(App);
app.use(store).use(router).mount('#app');

// let app;
// onAuthStateChanged(auth, async user => {
//   // CORE LOGIC (order is important)
//   // do not create app, if we dont know auth/unauth state
//   // if authed, await the token as well, since it is needed in some views (like profile)
//   // then make app. if this is a subsequent auth state change, ignore this step
//   // treat certain routes with redirects, given auth state

//   if (user) {
//     await user
//       .getIdToken(true)
//       .then(idToken => {
//         // Send token to your backend via HTTPS
//         store.commit("setToken", idToken);
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }
//   store.commit("setUser", user);

//   if (!app) {
//     app = createApp(App);
//     app
//     .use(store)
//     .use(router)
//     .mount("#app");
//   }

//   console.log(
//     "AUTH_STATE CHANGED:",
//     "User=", store.state.user,
//     "Token=", store.state.token,
//     "EmailVerified=", store.state.email_verified,
//   );
// });
