import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import Home from '@/views/Home.vue';
import Search from '@/views/Search.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER HOME:', store.state.user);
      next();
      // if (store.state.user != null) {
      //   next("/account");
      // } else {
      //   console.log("NOT Authed, redirecting..");
      //   next("/signin");
      // }
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Search:', store.state.user);
      next();
      // if (store.state.user != null) {
      //   next("/account");
      // } else {
      //   console.log("NOT Authed, redirecting..");
      //   next("/signin");
      // }
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: Home,
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER PageNotFound:', store.state.user);
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   console.log(to, from);
//   // ...
//   // explicitly return false to cancel the navigation
//   // return false;
// });

export default router;
