<template>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-trelos">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">[Canadian Multilingual Speech Database]</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/search">Search</router-link>
          </li>
        </ul>
        <!-- <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
  </nav>
</template>

<script>
import 'bootstrap';

export default {
  name: 'navigation',
  props: ['project_name'],
  methods: {},
};
</script>
<style scoped>
.bg-trelos {
  background-color: #025587;
}
</style>
