<template>
  <div class="container">
    <div class="bg-light p-5 rounded-lg mt-4 mb-3 m-2">
      <h1 class="display-4">Welcome to Speech Database!</h1>
      <p class="lead">
        <strong>
          The main aim of this project is to create a Canadian multilingual and multigenerational speech database.
        </strong>
        There has been a dramatic interest in research on second/foreign language speech learning (e.g., Flege, 1995;
        Colantoni &amp; Steele, 2008, Rafat, 2016), second dialect acquisition (e.g., MacLeod, 2012; Ruiz-Pena, Sevilla
        &amp; Rafat, 2015), first language phonetic and phonological attrition and change (e.g., Optiz, 2013; Rafat
        &amp; Mohaghegh, 2015; Schmid, 2014; Ulbrich &amp; Ordin, 2014), heritage languages (e.g.,Chang, Haynes &amp;
        Rhodes, 2011; Rafat &amp; Mohaghegh, 2015), as well as foreign accent (e.g., Boula de Mareüil, Vieru-Dimulescu,
        2006; Wieling, Bloem, Mignella, Timmermeister, &amp; Nerbonne, 2014).
      </p>
      <p class="lead">
        Although there is an
        <a href="http://accent.gmu.edu/" target="_blank">American Speech Accent Archive <i class="fa fa-external-link" aria-hidden="true"></i></a> 
        (Weinberger, 2014) and an
        <a href="http://andosl.rsise.anu.edu.au/andosl/" target="_blank">Australian National Database of Spoken Language <i class="fa fa-external-link" aria-hidden="true"></i></a>
        (Millar, Vonwiller, Harrington &amp; Dermody, 1994), we do not currently have a Canadian database that
        represents both the English and the native/heritage languages of immigrants in Canada and is publicly available
        to research community and those interested in languages. Canada is a multicultural immigrant receiving country,
        where both second language learning and preserving heritage languages are highly relevant, and valued.
      </p>
      <p class="lead">
        The creation of such a database is important for a number of reasons: 
        <ul>
          <li class="lead">First, it allows scholars to work on language change in a contact situation and understand how different varieties of English spoken evolve over the years and how native/heritage languages change in a contact situation.</li>
          <li class="lead">Second, this project will document the languages of the immigrant population in Canada, preserving our unique heritage cultures that make up the Canadian multicultural mosaic and identity.</li>
          <li class="lead">Third, the database will be used as a pedagogical tool in both schools and universities for teaching ESL, heritage languages and foreign accent reduction.</li>
        </ul> 
      </p>
      <button class="btn btn-primary btn-block mb-3" type="button" @click.prevent="goToSearch()">Search the Database</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToSearch: function () {
      var self = this;
      self.$router.push('/search');
    },
  },
};
</script>
