<template>
  <div class="container">
    <div class="row row-cols-1 row-cols-md-2 g-4 m-1">
      <div class="col">
        <h3><u>Search Filters</u></h3>
        <form class="mt-3">
          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Age Range:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect v-model="age_range" :options="age_range_options" placeholder="Select" :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Gender:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect v-model="gender" :options="gender_options" placeholder="Select" :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Ethnicity:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect v-model="ethnicity" :options="ethnicity_options" placeholder="Select" :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong># of Generations:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect
                v-model="num_generations"
                :options="num_generations_options"
                placeholder="Select"
                :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Level of Proficiency:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect
                v-model="level_proficiency"
                :options="level_proficiency_options"
                placeholder="Select"
                :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Age arrival:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect
                v-model="age_arrival"
                :options="age_arrival_options"
                placeholder="Select"
                :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Length of residence:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect
                v-model="length_residence"
                :options="length_residence_options"
                placeholder="Select"
                :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Education:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect v-model="education" :options="education_options" placeholder="Select" :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>First language:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect v-model="l1" :options="l1_options" placeholder="Select" :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Second language:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect v-model="l2" :options="l2_options" placeholder="Select" :searchable="true" />
            </div>
          </div>

          <div class="row mb-3">
            <label class="col-lg-4 col-form-label">
              <strong>Third language:</strong>
            </label>
            <div class="col-lg-8">
              <Multiselect v-model="l3" :options="l3_options" placeholder="Select" :searchable="true" />
            </div>
          </div>

          <!-- 					rec_id
						  age_range
						X  place birth
						  gender
						  ethnicity
						  num_generations
						  level_proficiency
						  age_arrival
						  length_residence
						X  place_residence
						x  occupation
						  education
						  l1
						X area_residence_outside
						X  other_influence_speech
						X  date_recording
						X  text
						X  lang_by_parents
						X  lang_dialects_spoken -->
        </form>

        <button
          class="btn btn-primary btn-block mb-3"
          type="button"
          :disabled="search_in_progress"
          @click.prevent="search()">
          <div v-if="search_in_progress" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          &nbsp; Search
        </button>
      </div>
      <div class="col ml-3">
        <h3><u>Search Results</u></h3>
        <div v-if="search_results && search_results.length == 0" class="mb-3">
          <strong class="redcolor">No recordings found matching these filters.</strong>
        </div>
        <div v-if="search_results && search_results.length > 0" class="mb-3">
          <strong class="redcolor">Found {{ search_results.length }} recordings matching these filters.</strong>
        </div>
        <div v-for="(item, index) in search_results" :key="index">
          {{ index + 1 }}) {{ pretty_format(item) }}
          <br />
          <audio controls>
            <source :src="`./audio/${item['rec_filename']}.wav`" type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      search_status: null,
      search_in_progress: false,
      search_results: null,

      age_range: null,
      age_range_options: null,
      gender: null,
      gender_options: null,
      ethnicity: null,
      ethnicity_options: null,
      num_generations: null,
      num_generations_options: null,
      level_proficiency: null,
      level_proficiency_options: null,
      age_arrival: null,
      age_arrival_options: null,
      length_residence: null,
      length_residence_options: null,
      education: null,
      education_options: null,
      l1: null,
      l1_options: null,
      l2: null,
      l2_options: null,
      l3: null,
      l3_options: null,
    };
  },
  methods: {
    search: function () {
      var self = this;
      self.search_status = null;
      self.search_in_progress = true;
      self.search_results = null;

      let recordings = [
        {
          'rec_id': '1',
          'rec_filename': '1',
          'age_range': '21#30',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'caucasian',
          'num_generations': '1',
          'level_proficiency': 'proficient',
          'age_arrival': '21#30',
          'length_residence': '4#5',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'farsi',
          'l2': 'english',
          'l3': 'french',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '2',
          'rec_filename': '2',
          'age_range': '11#20',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'caucasian',
          'num_generations': '1',
          'level_proficiency': 'proficient',
          'age_arrival': '21#30',
          'length_residence': '4#5',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'farsi',
          'l2': 'french',
          'l3': 'italian',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '3',
          'rec_filename': '3',
          'age_range': '1#10',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'caucasian',
          'num_generations': '1',
          'level_proficiency': 'proficient',
          'age_arrival': '21#30',
          'length_residence': '4#5',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'english',
          'l2': 'turkish',
          'l3': 'mandarin',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '4',
          'rec_filename': '1',
          'age_range': '61#70',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'caucasian',
          'num_generations': '1',
          'level_proficiency': 'proficient',
          'age_arrival': '21#30',
          'length_residence': '4#5',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'english',
          'l2': 'spanish',
          'l3': 'turkish',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '5',
          'rec_filename': '2',
          'age_range': '61#70',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'caucasian',
          'num_generations': '1',
          'level_proficiency': 'proficient',
          'age_arrival': '21#30',
          'length_residence': '4#5',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'french',
          'l2': 'english',
          'l3': 'italian',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '6',
          'rec_filename': '3',
          'age_range': '21#30',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'caucasian',
          'num_generations': '1',
          'level_proficiency': 'proficient',
          'age_arrival': '21#30',
          'length_residence': '4#5',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'french',
          'l2': 'turkish',
          'l3': 'mandarin',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '7',
          'rec_filename': '1',
          'age_range': '51#60',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'caucasian',
          'num_generations': '1',
          'level_proficiency': 'advanced',
          'age_arrival': '11#20',
          'length_residence': '1',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'turkish',
          'l2': 'hindi',
          'l3': 'mandarin',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '8',
          'rec_filename': '2',
          'age_range': '51#60',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'asian',
          'num_generations': '1',
          'level_proficiency': 'advanced',
          'age_arrival': '11#20',
          'length_residence': '1',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'turkish',
          'l2': 'italian',
          'l3': 'english',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '9',
          'rec_filename': '3',
          'age_range': '11#20',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'asian',
          'num_generations': '2',
          'level_proficiency': 'advanced',
          'age_arrival': '11#20',
          'length_residence': '1',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'italian',
          'l2': 'spanish',
          'l3': 'french',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '10',
          'rec_filename': '1',
          'age_range': '1#10',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'asian',
          'num_generations': '2',
          'level_proficiency': 'advanced',
          'age_arrival': '11#20',
          'length_residence': '1',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'italian',
          'l2': 'hindi',
          'l3': 'french',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '11',
          'rec_filename': '2',
          'age_range': '31#40',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'asian',
          'num_generations': '2',
          'level_proficiency': 'advanced',
          'age_arrival': '31#',
          'length_residence': '2#3',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'spanish',
          'l2': 'hindi',
          'l3': 'italian',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '12',
          'rec_filename': '3',
          'age_range': '21#30',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'asian',
          'num_generations': '2',
          'level_proficiency': 'advanced',
          'age_arrival': '31#',
          'length_residence': '2#3',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'spanish',
          'l2': 'english',
          'l3': 'spanish',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '13',
          'rec_filename': '1',
          'age_range': '31#40',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'asian',
          'num_generations': '2',
          'level_proficiency': 'advanced',
          'age_arrival': '0#5',
          'length_residence': '2#3',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'mandarin',
          'l2': 'french',
          'l3': 'hindi',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '14',
          'rec_filename': '2',
          'age_range': '41#50',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'asian',
          'num_generations': '2',
          'level_proficiency': 'lower',
          'age_arrival': '0#5',
          'length_residence': '2#3',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'mandarin',
          'l2': 'hindi',
          'l3': 'turkish',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '15',
          'rec_filename': '3',
          'age_range': '21#30',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'black',
          'num_generations': '2',
          'level_proficiency': 'lower',
          'age_arrival': '0#5',
          'length_residence': '6#',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'hindi',
          'l2': 'english',
          'l3': 'french',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '16',
          'rec_filename': '1',
          'age_range': '11#20',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'black',
          'num_generations': '3',
          'level_proficiency': 'lower',
          'age_arrival': '0#5',
          'length_residence': '6#',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'hindi',
          'l2': 'spanish',
          'l3': 'italian',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '17',
          'rec_filename': '2',
          'age_range': '61#70',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'black',
          'num_generations': '3',
          'level_proficiency': 'lower',
          'age_arrival': '6#10',
          'length_residence': '6#',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'english',
          'l2': 'french',
          'l3': 'hindi',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '18',
          'rec_filename': '1',
          'age_range': '61#70',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'black',
          'num_generations': '3',
          'level_proficiency': 'lower',
          'age_arrival': '6#10',
          'length_residence': '6#',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'french',
          'l2': 'turkish',
          'l3': 'hindi',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '19',
          'rec_filename': '2',
          'age_range': '21#30',
          'place_birth': '',
          'gender': 'female',
          'ethnicity': 'black',
          'num_generations': '3',
          'level_proficiency': 'lower',
          'age_arrival': '6#10',
          'length_residence': '6#',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'french',
          'l2': 'english',
          'l3': 'hindi',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
        {
          'rec_id': '20',
          'rec_filename': '3',
          'age_range': '41#50',
          'place_birth': '',
          'gender': 'male',
          'ethnicity': 'black',
          'num_generations': '3',
          'level_proficiency': 'lower',
          'age_arrival': '6#10',
          'length_residence': '6#',
          'place_residence': '',
          'occupation': '',
          'education': '',
          'l1': 'turkish',
          'l2': 'mandarin',
          'l3': 'hindi',
          'area_residence_outside': '',
          'other_influence_speech': '',
          'date_recording': '',
          'text': '',
          'lang_by_parents': '',
          'lang_dialects_spoken': '',
        },
      ];

      let all_filters = [
        { 'k': 'age_range', 'v': self.age_range },
        { 'k': 'gender', 'v': self.gender },
        { 'k': 'ethnicity', 'v': self.ethnicity },
        { 'k': 'num_generations', 'v': self.num_generations },
        { 'k': 'level_proficiency', 'v': self.level_proficiency },
        { 'k': 'age_arrival', 'v': self.age_arrival },
        { 'k': 'length_residence', 'v': self.length_residence },
        { 'k': 'education', 'v': self.education },
        { 'k': 'l1', 'v': self.l1 },
        { 'k': 'l2', 'v': self.l2 },
        { 'k': 'l3', 'v': self.l3 },
      ];
      console.log(all_filters);

      let given_filters = [];
      for (let index = 0; index < all_filters.length; index++) {
        const cf = all_filters[index];
        if (cf['v'] != null) {
          given_filters.push(cf);
        }
      }
      console.log(given_filters);

      let results = [];
      for (let index = 0; index < recordings.length; index++) {
        const elem = recordings[index];
        if (self.search_hit(elem, given_filters)) {
          results.push(elem);
        }
      }

      self.search_results = results;
      self.search_in_progress = false;
    },

    display_results: function (results) {
      var self = this;

      self.search_status = '';
      for (let index = 0; index < results.length; index++) {
        const res = results[index];
        self.search_status += `${index + 1}) ${JSON.stringify(res).replace(',', ' , ')}`;
        self.search_status += `
										<audio controls>
									  	<source src="./audio/${res['rec_filename']}.wav" type="audio/wav">
											Your browser does not support the audio element.
										</audio><br><br>`;
      }
    },

    search_hit: function (elem, given_filters) {
      for (let index = 0; index < given_filters.length; index++) {
        const cf = given_filters[index];
        if (cf['v'] != elem[cf['k']]) {
          return false;
        }
      }
      return true;
    },

    pretty_format: function (item) {
      let stringify = '';
      for (const [key, value] of Object.entries(item)) {
        console.log(`${key}: ${value}`);
        if (value != '') {
          stringify += `[${key}] = ${value.replace('#', '-')} `;
        }
      }
      return stringify;
    },

    init: function () {
      var self = this;
      self.age_range_options = [
        { 'label': '1-10', 'value': '1#10' },
        { 'label': '11-20', 'value': '11#20' },
        { 'label': '21-30', 'value': '21#30' },
        { 'label': '31-40', 'value': '31#40' },
        { 'label': '41-50', 'value': '41#50' },
        { 'label': '51-60', 'value': '51#60' },
        { 'label': '61-70', 'value': '61#70' },
      ];

      self.gender_options = [
        { 'label': 'Male', 'value': 'male' },
        { 'label': 'Female', 'value': 'female' },
      ];

      self.ethnicity_options = [
        { 'label': 'Caucasian', 'value': 'caucasian' },
        { 'label': 'Asian', 'value': 'asian' },
        { 'label': 'Black', 'value': 'black' },
      ];

      self.num_generations_options = [
        { 'label': '1st', 'value': '1' },
        { 'label': '2nd', 'value': '2' },
        { 'label': '3rd', 'value': '3' },
      ];

      self.level_proficiency_options = [
        { 'label': 'Proficient', 'value': 'proficient' },
        { 'label': 'Advanced', 'value': 'advanced' },
        { 'label': 'Low', 'value': 'lower' },
      ];

      self.age_arrival_options = [
        { 'label': '0-5 Years Old', 'value': '0#5' },
        { 'label': '6-10 Years Old', 'value': '6#10' },
        { 'label': '11-20 Years Old', 'value': '11#20' },
        { 'label': '21-30 Years Old', 'value': '21#30' },
        { 'label': '31+ Years Old', 'value': '31#' },
      ];

      self.length_residence_options = [
        { 'label': '1 Year', 'value': '1' },
        { 'label': '2-3 Years', 'value': '2#3' },
        { 'label': '4-5 Years', 'value': '4#5' },
        { 'label': '6+ Years', 'value': '6#' },
      ];

      self.education_options = [
        { 'label': 'High School', 'value': '1' },
        { 'label': 'College Degree', 'value': '2' },
        { 'label': 'University Degree', 'value': '3' },
        { 'label': 'University Masters', 'value': '4' },
        { 'label': 'University PhD', 'value': '5' },
      ];
      self.l1_options = [
        { 'label': 'English', 'value': 'english' },
        { 'label': 'Spanish', 'value': 'spanish' },
        { 'label': 'French', 'value': 'french' },
        { 'label': 'Italian', 'value': 'italian' },
        { 'label': 'Mandarin', 'value': 'mandarin' },
        { 'label': 'Farsi', 'value': 'farsi' },
        { 'label': 'Turkish', 'value': 'turkish' },
        { 'label': 'Hindi', 'value': 'hindi' },
      ];

      self.l2_options = [
        { 'label': 'English', 'value': 'english' },
        { 'label': 'Spanish', 'value': 'spanish' },
        { 'label': 'French', 'value': 'french' },
        { 'label': 'Italian', 'value': 'italian' },
        { 'label': 'Mandarin', 'value': 'mandarin' },
        { 'label': 'Farsi', 'value': 'farsi' },
        { 'label': 'Turkish', 'value': 'turkish' },
        { 'label': 'Hindi', 'value': 'hindi' },
      ];

      self.l3_options = [
        { 'label': 'English', 'value': 'english' },
        { 'label': 'Spanish', 'value': 'spanish' },
        { 'label': 'French', 'value': 'french' },
        { 'label': 'Italian', 'value': 'italian' },
        { 'label': 'Mandarin', 'value': 'mandarin' },
        { 'label': 'Farsi', 'value': 'farsi' },
        { 'label': 'Turkish', 'value': 'turkish' },
        { 'label': 'Hindi', 'value': 'hindi' },
      ];
    },
  },
  created() {
    var self = this;
    self.init();
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.redcolor {
  color: red;
}
</style>